import React from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import CloudNacResulPollaExt from "./cloudNacResulPollaExt";
import "./cloudnacResulPolla.scss";
import {moment} from './func_nac'

// const valuesCol={
//     lg:15,
//     xs:24
// }

const Mainpolla = () => {
	return (
		<Row className="frm-col-Polla">
			<Col >
				<CloudNacResulPollaExt unixMomemt={moment()} />
			</Col>
		</Row>
	);
};

export default Mainpolla;
