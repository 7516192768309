import React from "react";
import MainInfo from "./MainInfo";
import "antd/dist/antd.min.css";
function App() {
	return (
		<>
			<MainInfo />
		</>
	);
}

export default App;
