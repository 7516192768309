import React from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Tag from "antd/es/tag";
import { Typography } from 'antd';
import { colorWin, colorLtr,placesWinnerColorLT,placesWinnerColorGB } from "./globals.js";
const colSpam = {
	span: 2,
	offset: 2,
};

const { Title } = Typography;
export const columns = [
	{
		title: "La Polla de",
		dataIndex: "usu",
		key: `usu-${Math.floor(Math.random() * 100) + 1}`,
		render: (value,record)=>(
			<div key={`usu-${record.serial}`}>
				<Title level={3}>
				{value.toUpperCase()}
				
				</Title>
				{record.premio !==0 ?
				<Tag
					key={`${value.toUpperCase()}-${record.position}`}
					style={{ color:placesWinnerColorLT[Number(record.position) - 1] }}
					color={placesWinnerColorGB[Number(record.position) - 1]}
				>
					{record.position}
				</Tag>
				:<></>}
			</div>
		)
	},
	{
		title: "Jugada/Puntos",
		dataIndex: "Jnd",
		key: `Jnd-${Math.floor(Math.random() * 200) + 1}`,
		render: (values, record) => (
			<div key={`tk-${record.serial}`}>
				<Row>
					{values.split("-").map((tag, index) => {
						return (
							<Col {...colSpam}>
								<Tag
									key={`${index}-${tag}`}
									style={{ color: colorLtr[Number(tag) - 1] }}
									color={colorWin[Number(tag) - 1]}
								>
									{tag}
								</Tag>
							</Col>
						);
					})}
				</Row>
				<Row>
					{record.escrute.split("-").map((tag, index) => {
						return (
							<Col {...colSpam}>
								<Tag key={`${index}-${tag}`} color="#108ee9">
									{tag}
								</Tag>
							</Col>
						);
					})}
				</Row>
			</div>
		),
	},
	{
		title: "Puntos",
		dataIndex: "puntos",
		key: `puntos-${Math.floor(Math.random() * 300) + 1}`,
		sorter: (a, b) => a.puntos - b.puntos,
		render: (value,record)=>(
			<div key={`points-${record.serial}`}>
				<Title level={2}>
				{value}
				</Title>
			</div>
		)
	},
];
