import React, { useState,useEffect } from "react";
import Tabs from "antd/es/tabs";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Icon from "antd/es/icon";
import Spinnerwait from "./spinnerwait";
import { useData } from "./hoosk/useChange";
import { CloudnacResulPollaTab } from "./cloudnacResulPollaTab";

import {CODE_SUBSCRIPTION_RSMPOLLA} from './func_nac'
import Client from './conex';

const { TabPane } = Tabs;
let clientSubcribe = null
export default function CloudNacResulPollaExt({ moneda = "BSS" }) {
	const [unidMoneda, setstateUnidMoneda] = useState(moneda);
	const { stateMoneda, stateResumido,setstateResumidoPolla, loading } = useData(unidMoneda);
	

	const haldlenOnSelectMoneda = (e) =>{ 
		setstateUnidMoneda(e);
	}

	useEffect(()=>{
		if (clientSubcribe)	clientSubcribe.unsubscribe() 
		clientSubcribe=Client.subscribe({query:CODE_SUBSCRIPTION_RSMPOLLA,variables:{moneda:unidMoneda} }).subscribe({
			next({data:{RSMPolla}}){
				setstateResumidoPolla(RSMPolla)
			}
		})
		// return ()=> { if (clientSubcribe)	clientSubcribe.closed() }

	},[unidMoneda,setstateResumidoPolla])
	
	


	return (
		<>
			<Row className="frm-resultado-prn">
				{stateResumido.length === 0 ? (
					<Col span={24} className="frm-resultado-spinner">
						<Spinnerwait />
					</Col>
				) : (
					<Col span={24}>
						{/* <Row>
							<Col>
								<h1 className="fmr-title-polla">Resumen de Pollas</h1>
							</Col>
						</Row> */}
						<Row>
							{stateMoneda.map((value,index) => (
								<Col key={`col-${index.toString()}`} xs={8} sm={8} md={5} className="box-col">
									<button
										key={`btn-${index.toString()}`}
										onClick={() => haldlenOnSelectMoneda(value.moneda)}
										className={`box-moneda-btn ${
											unidMoneda === value.moneda ? "box-select" : ""
										}`}
									>
										<label>{`(${value.moneda}) ${value.descripcion}`}</label>
									</button>
								</Col>
							))}
						</Row>
						<Row>
							{loading ? (
								<Col span={24} className="frm-resultado-spinner">
									<Spinnerwait />
								</Col>
							) : (
								<Tabs tabBarGutter={10} tabBarStyle={{ color: "#fff" }}>
									{stateResumido.map((value, index) => (
										<TabPane
											key={`tbl-${index.toString()}`}
											tab={
												<div>
													<Icon
														type="calendar"
														style={{ fontSize: "20px" }}
														theme="twoTone"
														twoToneColor="yellow"
													/>
													<label className="rsl-polla-vl1">{value.Fecha}</label>
												</div>
											}
										>
											<CloudnacResulPollaTab
												index={index}
												registro={value.recaudaRsm}
												dataallpolla={value.recaudaTk}
												txtHipodromo={value.Hipodromo}
												moneda={unidMoneda}
											/>
										</TabPane>
									))}
								</Tabs>
							)}
						</Row>
					</Col>
				)}
			</Row>
		</>
	);
}
