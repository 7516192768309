import React from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Table from "antd/es/table";

import { columns } from "./cloudnacResulPollaTabla";
import CloudnacResulPollaEscrutinio from './cloudnacResulPollaEscrutinio'

export const CloudnacResulPollaTab = (
	{index, moneda, registro, dataallpolla, txtHipodromo } 
) => {

	return (
		<Row  key={`row-table-${index.toString()}`}  className="rsm-info-polla">
			<Row className="info-info-polla">
				<Col key={`col1-table-${index.toString()}`} span={24} className="rsl-row-Info">
					<Row type="flex" justify="center">
						<Col lg={24} xs={24}>
							<CloudnacResulPollaEscrutinio 
								moneda={moneda}
								registro={registro}
								txtHipodromo={txtHipodromo}
								cantidad={dataallpolla.length}
								dataallpolla={dataallpolla}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col key={`col2-table-${index.toString()}`}  span={24} className="rsl-row-table">
					<Row type="flex" justify="space-around" align="middle">
						<Table
							key={`table-${index}`}
							rowKey={(record) => record.serial}
							columns={columns}
							dataSource={dataallpolla}
							expandedRowRender={(record) => (
								<p key={`ptk-${index}-${record.serial}`} style={{ margin: 0 }}>
									Ticke No:{record.serial}, Hora:{record.hora} {record.premio !== 0 ?`,Premio: ${parseFloat(record.premio).toFixed(2)}`:''}
								</p>
							)}
						/>
					</Row>
				</Col>
			</Row>
		</Row>
	);
};
