import React from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Tag from "antd/es/tag";
import { placesWinnerColorGB, placesWinnerColorLT } from "./globals.js";

const CloudnacResulPollaEscrutinio = ({moneda,txtHipodromo,registro,dataallpolla,cantidad}) => {
	const resumen_x_winners =[]
    const { totalVenta, premio, Puntostotal,config, dolar,idm} = registro;
	const zsuma = dataallpolla.reduce((acc, cur) => {
		return acc + cur.puntos;
	}, 0);
	let ppGan = 0;
	if (zsuma !== 0) {
		
		dataallpolla.forEach((element) => {

			if (element.premio !== 0) {
				ppGan++;
				const position = element.position
				const idx=  resumen_x_winners.findIndex(({p})=> p === position)
				if ( idx  === -1) {
					resumen_x_winners.push({
						p:position,
						premios_total: element.premio,
						premio_dolar :0 
					})
				}else{
					resumen_x_winners[idx].premios_total+=element.premio
				}
			}
		});

	

    }
	if (resumen_x_winners.length===0){
		const dataSetting = JSON.parse(config)
		dataSetting.forEach((r,position)=>{
			r.forEach(e=>{
			if (e.id === idm ){
				const monto = parseFloat(e.monto)
				if (monto !==0){
					const premios_total = e.tipo === 1? totalVenta * monto /100:monto
					resumen_x_winners.push({
						p:(position+1),
						premios_total,
						premio_dolar :dolar!==0?(premios_total/parseFloat(dolar)).toFixed(2):0
					})
				}
			}
		})
		})
	}else{
		resumen_x_winners.forEach((e,i)=>{
			resumen_x_winners[i].premio_dolar=dolar!==0?(e.premios_total/parseFloat(dolar)).toFixed(2):0
		})
	}
	return (
		<div  className="boxEscrutinio">
			<Row className="rsm-info-alert">
				<Col span={24}>
					<Row>
						<Col className="info-lbl-polla inf-alt info-title" span={12}>
							Hipodromo:
						</Col>
						<Col className="inf-alt" span={12}>
							{txtHipodromo}
						</Col>
					</Row>
					<Row>
						<Col className="info-lbl-polla info-title" span={12}>
							Total Pollas:
						</Col>
						<Col className="info-lbl-polla" span={12}>
							{new Intl.NumberFormat("es", {
								style: "currency",
								currency: moneda,
							}).format(totalVenta)}
						</Col>
					</Row>
					<Row>
						<Col className="info-lbl-polla inf-alt info-title" span={12}>
							a Repartir:
						</Col>
						<Col className="inf-alt" span={12}>
							{new Intl.NumberFormat("es", {
								style: "currency",
								currency: moneda,
							}).format(premio)}
						</Col>
					</Row>
					<Row>
						<Col className="info-lbl-polla  info-title" span={12}>
							Total de Pollas:
						</Col>
						<Col className="info-lbl-polla  " span={12}>
							{cantidad}
						</Col>
					</Row>
					<Row>
						<Col className="info-lbl-polla  inf-alt info-title" span={12}>
							Puntos:
						</Col>
						<Col className="info-lbl-polla  inf-alt" span={12}>
							{Puntostotal?Puntostotal:0}
						</Col>
					</Row>
					<Row>
						<Col className="info-lbl-polla info-title" span={12}>
							Ganadores:
						</Col>
						<Col className="info-lbl-polla " span={12}>
							{ppGan}
						</Col>
					</Row>
					{resumen_x_winners.map(o=>{
						return (
							<Row>
								<Col className="info-lbl-polla info-title" span={12}>
									{"Monto para "} 
									<Tag
											key={`${o}`}
											style={{ color:placesWinnerColorLT[Number(o.p) - 1] }}
											color={placesWinnerColorGB[Number(o.p) - 1]}
										>
											{o.p}
										</Tag>
										Lugar 
								</Col>
								<Col className="info-lbl-polla " span={12}>
								{new Intl.NumberFormat("es", {
								style: "currency",
								currency: moneda,
							}).format(o.premios_total)
							}{
								o.premio_dolar !== 0
								?` ( ${o.premio_dolar}$ BCV )`
							:""
							}
								</Col>
							</Row>
						)
					})}
				</Col>
			</Row>
		</div>
	);
};

export default CloudnacResulPollaEscrutinio;
