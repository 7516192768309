import { useState, useEffect } from "react";
import { queryresumidoPollaFN, queryMonedasFn } from "../func_nac";

export const useData = (unidMoneda) => {
	const [stateMoneda, setstateMoneda] = useState([]);
    const [stateResumido, setstateResumidoPolla] = useState([]);
    const [loading, setLoading] = useState(false);

	const callChangeRsm = (unidMoneda) => {
		return queryresumidoPollaFN(unidMoneda).then((data) => {
			setLoading(false)
			return data;
		});
	};
	useEffect(() => {
        setLoading(true)
		callChangeRsm(unidMoneda).then(setstateResumidoPolla);
		return () => {};
	},[unidMoneda]);

	useEffect(() => {
		queryMonedasFn().then(setstateMoneda);
	}, []);

	

	return { stateResumido, stateMoneda ,callChangeRsm,setstateResumidoPolla,loading};
};
