
export const graphqlUrl = "https://pollacasagrande.com:4433/serviceV2";
export const graphqlTEST = "http://localhost:8033/serviceV2";

export const subcripUrl = "wss://pollacasagrande.com:4433/serviceV2";
export const subcripTEST = "ws://localhost:8033/serviceV2";


export const SECRETO = '6c84fb90-12c4-11e1-840d-7b25c5ee775a';
export const Nivel = 0;
export const jti = '0';
export const dia = 'hoy';
export const chat = 'http://68.183.17.129:4005';
export const url = "https://firebasestorage.googleapis.com/v0/b/data-avatar-d75e7.appspot.com/o/";
export const styleBg = ['#108ee9', '#590101', '#0f5901', '#9b9842', '#8c429b'];
export const colorWin = ['#6f0303', '#fff', '#021629', '#ada31b', '#07330f', '#000', '#ab7481', '#88432b', '#188281', '#410163', '#8A8A8A', '#709879', '#6C4735', '#5D1231', '#ada8a2', '#71739a'];
export const colorLtr = ['#fff', '#000', '#fff', '#000', '#fff', 'yellow', 'black', 'black', 'black', 'white', 'white', 'black', 'white', 'white', 'black', 'white'];
export const placesWinnerColorGB=['#daa520','#708090','#f4a460']
export const placesWinnerColorLT=['#000000','#fff','#000000']