import React from "react";
import Layout from "antd/es/layout";
import { Spring } from "react-spring/renderprops";
import Mainpolla from "./Mainpolla";
import "./MainInfo.scss";
import "./Mainapp.scss";

const { Header,  Content } = Layout;
const valuePropsSpring={
	delay:300, from:{ opacity: 0 }, to:{ opacity: 1 }
}
const MainInfo = () => {
	return (
		<div className="seccion3">
			<Header className="header-main">
				<div className="MenuPrim">
					<div className="boxHead_p2">
						<label className="boxHead_p2-label1">CASA</label>
						<label className="boxHead_p2-label2">GRANDE</label>
						<label className="boxHead_p2-label3">Group</label>
					</div>
				</div>
			</Header>
			<Content>
					<div >					
						<Spring  {...valuePropsSpring}>
							{(props) => (
								<div style={props} className="content-main">
									<Mainpolla />
								</div>
							)}
						</Spring>
						</div>
	
			</Content>
		</div>
	);
};

export default MainInfo;
